import { findNodeById } from "../../utils";
export default {
  data() {
    return {
      isActive: false,
      isHover: false,
      isHoverOnProperElement: false,
      hoverTimeout: null,
      isMouseOver: false,
      selfIndexToJoin: 0,
      selfIndexMakesMerge: 0,
      nodeIndexMakeMergeTojoin: 0,
      currentNodeHaveMerge: false,
      currentNodeMakesMerge: false,
    };
  },
  inject: ["completeTreeProp"],
  methods: {
    showAddButton() {
      if (!this.currentNodeHaveMerge) {
        let addButton = document.getElementById(this.item.id);
        addButton.style.display = "block";
      }
    },
    hideAddButton() {
      if (!this.currentNodeHaveMerge) {
        let addButton = document.getElementById(this.item.id);
        addButton.style.display = "none";
      }
    },
    setHoverTimeout() {
      this.hoverTimeout = setTimeout(() => {
        if (!this.isMouseOver) {
          this.isActive = false;
        }
      }, 400); // Tempo de atraso de 500ms
    },
    handleMouseLeave() {
      this.isMouseOver = false;
      this.setHoverTimeout();
    },
    handleMouseOver() {
      this.isMouseOver = true;
      this.cancelHoverTimeout();
      this.isActive = true;
    },
    handleMouseEnter() {
      this.isMouseOver = true;
      this.handleMouseOver();
    },
    cancelHoverTimeout() {
      if (this.hoverTimeout !== null) {
        clearTimeout(this.hoverTimeout);
        this.hoverTimeout = null;
      }
    },
    handleMouseOverOnProperElement() {
      this.isHoverOnProperElement = true;
      this.isActive = false;
      this.cancelHoverTimeout();
    },
    handleMouseLeaveOnProperElement() {
      this.isHoverOnProperElement = false;
    },
    async countChildrenOnDiamondUntilJoin(tree, nodeInfo) {
      let count = 0;

      if (nodeInfo?.formatType == "diamond") {
        return count;
      }

      if (nodeInfo.lastNode || nodeInfo.lastStepId) {
        count++;
        //  entontrar o nó que tem o lastId correspondente
        const LAST_NODE = await findNodeById(
          tree,
          nodeInfo.lastNode || nodeInfo.lastStepId
        );
        // se encontrar eu passo esse nó no método recursivo
        if (LAST_NODE) {
          count += await this.countChildrenOnDiamondUntilJoin(tree, LAST_NODE);
        }
      }

      return count;
    },

    async countChildrenOnDiamondUntilFrontJoin(tree, nodeInfo) {
      let count = 0;

      if (nodeInfo?.formatType == "diamond") {
        return count;
      }

      if (nodeInfo.nextNode.lastNode || nodeInfo.nextNode.lastStepId) {
        count++;
        //  entontrar o nó que tem o lastId correspondente
        const LAST_NODE = await findNodeById(
          tree,
          nodeInfo.nextNode.lastNode || nodeInfo.nextNode.lastStepId
        );
        // se encontrar eu passo esse nó no método recursivo
        if (LAST_NODE) {
          count += await this.countChildrenOnDiamondUntilJoin(tree, LAST_NODE);
        }
      }

      return count;
    },
    async findFirstDiamondParent(tree, nodeInfo) {
      if (nodeInfo.formatType == "diamond") return nodeInfo;

      if (nodeInfo.lastNode || nodeInfo.lastStepId) {
        //  entontrar o nó que tem o lastId correspondente
        const LAST_NODE = await findNodeById(
          tree,
          nodeInfo.lastNode || nodeInfo.lastStepId
        );

        // se encontrar eu passo esse nó no método recursivo
        if (LAST_NODE)
          return await this.findFirstDiamondParent(tree, LAST_NODE);
      }

      return null;
    },
    async verifyIfNodeHaveMerge() {
      // verificar se meu componente tem um proximo nó de merge
      const have_merged_next = this.item.nextJoinNode;

      // verifica se o componente faz merge com alguem (tem um ultimo nó que faz link com ele)
      // para funcionar, vou ter qeu verificar se o componente da frente faz merge ao invés do prorpio nó
      // const front_node_makes_merge = this.item.lastJoinNode;
      const front_node_makes_merge =
        this.item.nextNode && this.item.nextNode.lastJoinNode;

      this.currentNodeHaveMerge =
        have_merged_next !== null && have_merged_next !== undefined;

      this.currentNodeMakesMerge =
        front_node_makes_merge != null && front_node_makes_merge != undefined;

      // caso ele tenha nó de merge, ele obrigatoriamente está no mesmo diamante de onde o seu nextJoinNode aponta
      // logo o componente precisa saber onde ele se encontra e onde o seu proximo nó se encontra (index)

      if (have_merged_next && this.item.formatType !== "diamond") {
        // POSIÇÃO DO ELEMENTO QUE TEM UM JOIN: nextJoinNode
        const COUNT_1 = await this.countChildrenOnDiamondUntilJoin(
          this.completeTreeProp,
          this.item
        );

        // POSIÇÃO DO ELEMENTO QUE TEM UM JOIN: lastJoinNode
        // precisa achar o diamante pai do elemento
        const PARENT_DIAMOND = await this.findFirstDiamondParent(
          this.completeTreeProp,
          this.item
        );

        if (PARENT_DIAMOND) {
          // preciso percorrer a linha oposta do diamante pai, até encontrar o cara para onde eu estou apontando (nextJoinNode)

          // preciso percorrer a linha inversa, para achar meu elemento
          // como por hora vai ser clicado apenas no next, manter somente assim, caso haja alteração, incluir informações
          if (this.item.fromClick === "yes") {
            // buscar meu nó por id, para fazer a busca
            const NODE_ON_NEXT = await findNodeById(
              this.completeTreeProp,
              this.item.nextJoinNode || this.item.yesNextStepId
            );

            // percorrer a linha do next para encontrar o index do elemento desejado
            if (NODE_ON_NEXT) {
              const COUNT_2 = await this.countChildrenOnDiamondUntilJoin(
                this.completeTreeProp,
                NODE_ON_NEXT
              );

              // para fazer a lógica para a linha do não, só usar os valores de forma invertida, visto que achamos ambos os valores
              this.selfIndexToJoin = COUNT_1;
              this.nodeIndexToJoin = COUNT_2;
            }
          }
        }
      }

      if (front_node_makes_merge && this.item.formatType !== "diamond") {
        // POSIÇÃO DO ELEMENTO QUE TEM UM JOIN: nextJoinNode
        const COUNT_1 = await this.countChildrenOnDiamondUntilFrontJoin(
          this.completeTreeProp,
          this.item
        );

        // precisa achar o diamante pai do elemento
        const PARENT_DIAMOND = await this.findFirstDiamondParent(
          this.completeTreeProp,
          this.item
        );

        if (PARENT_DIAMOND) {
          // preciso percorrer a linha oposta do diamante pai, até encontrar o cara para onde eu estou apontando (nextJoinNode)

          // preciso percorrer a linha inversa, para achar meu elemento
          // como por hora vai ser clicado apenas no next, manter somente assim, caso haja alteração, incluir informações
          if (this.item.fromClick === "next") {
            // buscar meu nó por id, para fazer a busca
            const NODE_ON_YES = await findNodeById(
              this.completeTreeProp,
              this.item.nextNode.lastJoinNode ||
                this.item.nextNode.yesLastStepId
            );

            // percorrer a linha do yes para encontrar o index do elemento desejado
            if (NODE_ON_YES) {
              const COUNT_2 = await this.countChildrenOnDiamondUntilJoin(
                this.completeTreeProp,
                NODE_ON_YES
              );

              //  console.log(COUNT_2, '<< yes', COUNT_1, '<< no', this.item.label)

              // this.selfIndexToJoin = COUNT_1;
              // this.nodeIndexToJoin = COUNT_2;

              this.selfIndexMakesMerge = COUNT_1;
              this.nodeIndexMakeMergeTojoin = COUNT_2;
            }
          }
        }
      }
    },
  },

  async created() {
    await this.verifyIfNodeHaveMerge();
  },
  beforeDestroy() {
    this.cancelHoverTimeout();
  },
};
