<template>
  <div class="square">
    <div
      v-if="isActive || isHoverOnProperElement"
      class="square__remove"
      @mouseover.self="handleMouseOverOnProperElement"
      @mouseleave.self="handleMouseLeaveOnProperElement"
    >
      <v-icon small @click.self="$refs.contextMenu.open($event, 'data')">
        mdi-pencil
      </v-icon>
    </div>
    <div
      class="square__content"
      :style="{
        'background-color': getColor,
        border: shouldValidate ? '1px solid red !important' : '',
        border:
          clickedNodesList.length &&
          clickedNodesList.find((el) => el.id == item.id) &&
          !shouldValidate
            ? '3px dashed #E46651 !important'
            : '',
      }"
      @click="$emit('node_click', item.id)"
      @mouseover.self="handleMouseOver"
      @mouseenter.self="handleMouseEnter"
      @mouseleave.self="handleMouseLeave"
    >
      <z-context-menu
        ref="contextMenu"
        :options="optionsMenu"
        @click="onClickContextAction"
      />
      <div class="square__content--not-rotate">
        <div
          class="square__content--not-rotate__icon"
          :style="
            item.type.includes('mensageriaCheck') ? 'padding-top: 35px !important;' : ''
          "
        >
          <v-icon v-if="item.type.includes('selectTestABFilter')" color="white">
            $call_split_rounded</v-icon
          >
          <v-icon v-else-if="item.type.includes('mensageriaCheck')" color="white"
            >mdi-email</v-icon
          >
          <v-icon v-else color="white"> mdi-contacts</v-icon>
        </div>
        <div class="square__content--not-rotate__text">
          {{ item.label }}
        </div>
      </div>
    </div>
    <div>
      <ExampleItemLine
        :title="
          item.type !== 'selectTestABFilter'
            ? 'Não'
            : item.percentageB
            ? item.percentageB.toString() + '%'
            : '0%'
        "
        :isReportRender="isReportRender"
        :item="item"
        @click="$emit('no_click', item.id)"
      />
      <div
        @mouseover="showAddButtonSIM()"
        @mouseleave="hideAddButtonSIM()"
        class="square__line yes-line"
        style=""
        :style="{
          height: `${heightYesLine}px `,
          bottom: `-${bottomYesLine}px`,
          width: '150px',
          marginLeft: '12px',
        }"
      >
        <span
          :id="item.id + '_sim_span'"
          style="background: #f4f4f4; color: black; font-weight: 500; padding: 3px"
        >
          {{
            item.type !== "selectTestABFilter"
              ? "Sim"
              : item.percentageA
              ? item.percentageA.toString() + "%"
              : "0%"
          }}
        </span>

        <figure
          v-if="!isReportRender"
          @click="$emit('yes_click', item.id)"
          :id="item.id + '_sim'"
          :style="{
            bottom: `-17px !important`,
            display: 'none',
            position: 'relative',
            top: `${topYesLineButton}px !important`,
            left: '-7px',
            zIndex: '5 !important',
          }"
        >
          <img
            style="margin-left: 15px; position: relative"
            src="/icons/add-circle-rounded-icon.svg"
          />
        </figure>

        <div class="yes-line__content">
          <slot name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shapesMixin from "./shapesMixin";
import ExampleItemLine from "./ExampleItemLine.vue";
export default {
  name: "FlowDiamond",
  components: {
    ExampleItemLine,
  },
  mixins: [shapesMixin],
  inject: ["isToUpdateDiamond", "clickedNodesList"],
  props: {
    item: { type: Object, default: null, required: true },
    icon: { type: String, default: "" },
    color: { type: String, default: "#F24822" },
    hasNextNode: { type: Boolean, default: false },
    shouldValidate: { type: Boolean, default: false },
    isReportRender: { type: Boolean, required: false, default: false },
  },

  watch: {
    // async isToUpdateDiamond() {
    //   this.numberOfChildsOnNoLine = await this.getNumberOfDiadmonsChilds(
    //     this.item.nextNode,
    //     "diamond"
    //   );
    //   this.numberOfChildsOnYesLine = await this.getNumberOfDiadmonsChilds(
    //     this.item.YesNext,
    //     "diamond"
    //   );
    // },
  },
  async created() {
    this.numberOfDiamondChilds = await this.getNumberOfDiadmonsChilds(
      this.item.nextNode,
      "diamond"
    );
    this.numberOfChildsOnYesLine += await this.getNumberOfDiadmonsChilds(
      this.item.YesNext,
      "diamond"
    );

    await this.siblingHeight();
  },
  data() {
    return {
      brotherDiamondHeight: 0,
      grandchildDiamondHeight: 0,
      grandchildDiamondHasAbrother: false,
      grandChildDiamondBrothersQuantity: 0,
      diamondMakeJoin: false,
      numberOfDiamondChilds: 0,
      numberOfChildsOnNoLine: 0,
      numberOfChildsOnYesLine: 0,
      optionsMenu: [
        { action: "delete", text: "Remover", value: 0 },
        { action: "rename", text: "Renomear", value: 1 },
      ],
    };
  },
  computed: {
    getColor() {
      const SELECT_COLOR = !this.item.type.includes("mensageriaCheck")
        ? !this.item.type.includes("selectTestABFilter") &&
          this.item.isDataSelected &&
          this.item.filterId
          ? this.color
          : this.item.type.includes("selectTestABFilter") &&
            this.item.isDataSelected &&
            +this.item.percentageA >= 0 &&
            +this.item.percentageB >= 0
          ? "#0d99ff"
          : "#DCDCDC"
        : this.item.mensageriaStepCheckId != null
        ? this.color
        : "#DCDCDC";

      return SELECT_COLOR;
    },

    heightYesLine() {
      // let d = 30;

      let d = this.numberOfDiamondChilds * 80;
      if (d === 0) d = 30;
      else d += 100;

      // if (this.numberOfChildsOnNoLine) {
      //   let ACC = 0;

      //   while (ACC < this.numberOfChildsOnNoLine) {
      //     d += 90;
      //     ACC++;
      //   }
      // }

      // if (this.numberOfChildsOnYesLine) {
      //   let ACC_2 = 0;

      //   while (ACC_2 < this.numberOfChildsOnYesLine) {
      //     d += 90;
      //     ACC_2++;
      //   }
      // }

      // if (this.numberOfChildsOnNoLine) {
      //   let CUSTOM_PERCENT = 60;
      //
      //   let PERCENT_YES = (d * CUSTOM_PERCENT) / 100;
      //   let ACC_3 = 0;
      //
      //   while (ACC_3 < this.numberOfChildsOnYesLine) {
      //     d += PERCENT_YES;
      //     ACC_3++;
      //   }
      // }

      // caso meu nó atual tenha um irmão  e esse irmão tenha filhos
      if (
        this.grandchildDiamondHasAbrother &&
        this.grandChildDiamondBrothersQuantity > 1
      ) {
        d += this.grandchildDiamondHeight;
        d += this.brotherDiamondHeight; // SOMAR A ALTURA DO IRMÃO TAMBÉM?
      }

      return d;
    },

    bottomYesLine() {
      // let label = this.item.label;

      let d = this.numberOfDiamondChilds * 100;
      if (d === 0) d = 100;
      else d += 150;

      //  caso meu nó atual tenha um irmão  e esse irmão tenha filhos
      if (
        this.grandchildDiamondHasAbrother &&
        this.grandChildDiamondBrothersQuantity > 1
      ) {
        let info = this.grandchildDiamondHeight * 1.5;
        d += info;

        d += this.brotherDiamondHeight; //SOMAR A ALTURA DO IRMÃO TAMBÉM?
      }

      // console.log(
      //   `Label: ${label} -- Altura: ${d} -- Num Diamonds: ${this.numberOfDiamondChilds} `
      // );
      return d;
    },

    topYesLineButton() {


      let d = this.numberOfDiamondChilds * 39;
      if (d === 0) d = 18;
      else d += 59;

      // let d = 18;
      // let NODE_YES_ITEMS_NUMBER = this.test(this.item, "diamond");

      // // console.log(NODE_YES_ITEMS_NUMBER, this.item.label)

      // // CADA FILHO VAI REPRESENTAR 150% DO VALOR DE D
      // if (this.numberOfChildsOnNoLine) {
      //   let ACTUAL_PERCENT = (d * 155) / 100;
      //   let ACC = 0;

      //   while (ACC < this.numberOfChildsOnNoLine) {
      //     d += ACTUAL_PERCENT;
      //     ACC++;
      //   }
      // }

      // if (this.numberOfChildsOnYesLine) {
      //   let PERCENT_YES = (d * 170) / 100;
      //   let ACC_2 = 0;

      //   while (ACC_2 < this.numberOfChildsOnYesLine) {
      //     d += PERCENT_YES;
      //     ACC_2++;
      //   }
      // }

      // if (this.numberOfChildsOnNoLine && NODE_YES_ITEMS_NUMBER) {
      //   let CUSTOM_PERCENT = 73;

      //   let PERCENT_YES = (d * CUSTOM_PERCENT) / 100;
      //   let ACC_3 = 0;

      //   while (ACC_3 < NODE_YES_ITEMS_NUMBER) {
      //     d += PERCENT_YES;
      //     ACC_3++;
      //   }
      // }

      if (
        this.grandchildDiamondHasAbrother &&
        this.grandChildDiamondBrothersQuantity > 1
      ) {
        let info = this.grandchildDiamondHeight / 2;
        let info_2 = this.brotherDiamondHeight / 1.5;
        d += info;
        d += info_2; //SOMAR A ALTURA DO IRMÃO TAMBÉM?
      }

      return d;
    },
  },
  methods: {
    async siblingHeight() {
      const brother = this.findBrother(this.item, this.item.id);

      if (brother) {
        const brother_height = await this.calculateLineHeight(brother);
        this.brotherDiamondHeight = brother_height;

        // preciso pegar a altura tb do primeiro neto diamante do primeiro filho
        const grand_child = await this.getFirstGrandChildDiamond(
          brother,
          brother.id,
          "diamond"
        );

        // caso encontrado, calcular a altura do neto
        if (grand_child) {
          const grand_child_height = await this.calculateLineHeight(grand_child);
          this.grandchildDiamondHeight = grand_child_height;

          // preciso fazer mais uma verificação, necessário verificar se o neto tem algum irmão diamante
          const grand_child_has_a_brother = this.findBrother(grand_child, grand_child.id);

          grand_child_has_a_brother
            ? (this.grandchildDiamondHasAbrother = true)
            : (this.grandchildDiamondHasAbrother = false);

          // calcular a quantidade de netos(irmãos) do item
          this.grandChildDiamondBrothersQuantity = this.countSiblingGrandchildren(
            this.item,
            this.item.id
          );
        }
      }

      return 0;
    },
    async calculateLineHeight(brother) {
      let d = 30;

      let NODE_YES_ITEMS_NUMBER = this.getElementsChildesSizes(
        brother,
        "YesNext",
        "diamond"
      );

      const NUMBER_CHILDS_NEXT_NODE = await this.getNumberOfChildsOnNoLine(
        brother,
        "diamond"
      );

      const NUMBER_CHILDS_YES_NEXT = await this.getNumberOfChildsOnYesLine(
        brother,
        "diamond"
      );

      if (NUMBER_CHILDS_NEXT_NODE) {
        let ACTUAL_PERCENT = (d * 190) / 100;
        let ACC = 0;

        while (ACC < NUMBER_CHILDS_NEXT_NODE) {
          d += ACTUAL_PERCENT;
          ACC++;
        }
      }

      if (NUMBER_CHILDS_YES_NEXT) {
        let CUSTOM_PERCENT = 200;
        let PERCENT_YES = (d * CUSTOM_PERCENT) / 100;
        let ACC_2 = 0;

        while (ACC_2 < NUMBER_CHILDS_YES_NEXT) {
          d += PERCENT_YES;
          ACC_2++;
        }
      }

      if (NUMBER_CHILDS_NEXT_NODE && NODE_YES_ITEMS_NUMBER) {
        let CUSTOM_PERCENT = 60;

        let PERCENT_YES = (d * CUSTOM_PERCENT) / 100;
        let ACC_3 = 0;

        while (ACC_3 < NODE_YES_ITEMS_NUMBER) {
          d += PERCENT_YES;
          ACC_3++;
        }
      }

      return d;
    },
    // async verifyIfCurrentDiamondMakesJoin(node) {
    //   // if (node?.lastJoinNode || node?.nextJoinNode) {
    //   //   console.log(node.label, 'tem')
    //   //   return true;
    //   // }
    //   // procuramos só o que tem lastJoinNode, visto que ele que faz a ligação com alguem na linha do nextnode
    //   if (node?.lastJoinNode) {
    //     // console.log(node.label, 'tem')
    //     return true;
    //   } else {
    //     if (node.nextNode) {
    //       // console.log(node.label, '<= estou no')
    //       await this.verifyIfCurrentDiamondMakesJoin(node.nextNode);
    //     }

    //     if (node.YesNext) await this.verifyIfCurrentDiamondMakesJoin(node.YesNext);

    //     return false;
    //   }
    // },
    // async gg(node, source) {
    //   if (node === null) return null;
    //   else {
    //     if (node?.lastJoinNode) {
    //       console.log("asdf", node);
    //       return node;
    //     } else if (source && node[source]) await this.gg(node[source], source);
    //     else return null;
    //   }
    // },
    onClickContextAction({ option }) {
      switch (option.value) {
        case 0:
          this.deleteContent();
          break;
        case 1:
          this.renameContent();
          break;
      }
    },

    renameContent() {
      if (this.item.label.toLowerCase() != "final") this.$emit("rename_node", this.item);
    },

    deleteContent() {
      this.$emit("node_click_right", this.item.id);
    },

    showAddButtonSIM() {
      let addButton = document.getElementById(this.item.id + "_sim");
      let simSpan = document.getElementById(this.item.id + "_sim_span");
      addButton.style.display = "block";
      simSpan.style.display = "none";
    },

    hideAddButtonSIM() {
      let addButton = document.getElementById(this.item.id + "_sim");
      let simSpan = document.getElementById(this.item.id + "_sim_span");
      simSpan.style.display = "block";
      addButton.style.display = "none";
    },

    getNumberOfChildsOnNoLine(node, target) {
      // RETORNAR UM POR DEFAULT, PORQUE SE EU FIZER MULTIPLICAÇÃO POR 0, O RESULTADO É 0
      if (node == null) return 0;
      else {
        if (node?.nextNode && node?.nextNode?.formatType == target) {
          return 1 + this.getNumberOfChildsOnNoLine(node.nextNode, target);
        } else {
          return this.getNumberOfChildsOnNoLine(node.nextNode, target);
        }
      }
    },

    countSiblingGrandchildren(node, rootDiamondId) {
      const brother = this.findBrother(node, rootDiamondId);

      if (brother) {
     //   console.log("entrou");
        return this.countGrandchildren(node, brother.id);
      }

      return 0;
    },

    findBrother(node) {
      if (!node) return null;
      else {
        if (node.nextNode && node?.nextNode?.formatType == "diamond")
          return node.nextNode;
        else return this.findBrother(node.nextNode);
      }
    },

    countGrandchildren(node, targetDiamondId) {
      let count = 0;

      if (!node) return 0;

      if (node.id === targetDiamondId) {
        // Encontrou o diamante raiz pai
        if (node.YesNext) {
          // Conta os netos
          count += this.countDiamonds(node.YesNext);
        }
      } else {
        if (node.nextNode) {
          count += this.countGrandchildren(node.nextNode, targetDiamondId);
        }
        if (node.yesNext) {
          count += this.countGrandchildren(node.yesNext, targetDiamondId);
        }
      }

      return count;
    },

    countDiamonds(node) {
      let count = 0;

      if (node.formatType == "diamond") {
        count++;
      }
      // apenas pesquiso nos campos nexts, porque preciso saber o numero de filhos diretos
      if (node.nextNode) {
        count += this.countDiamonds(node.nextNode);
      }

      // if (node.YesNext) {
      //   count += this.countDiamonds(node.YesNext);
      // }

      return count;
    },

    async getFirstGrandChildDiamond(node, father_id, target) {
      if (!node) return null;

      if (node.id != father_id && node.formatType == target) return node;

      if (node.YesNext)
        return this.getFirstGrandChildDiamond(node.YesNext, father_id, target);

      return null;
    },

    testeDiretoFIlhosProximo(node, target) {
      // RETORNAR UM POR DEFAULT, PORQUE SE EU FIZER MULTIPLICAÇÃO POR 0, O RESULTADO É 0
      if (node == null) return 0;
      else {
        if (node?.nextNode && node?.nextNode?.formatType == target) {
          // criar elemento pai com o proximo nó diamante do nó atual
          const FATHER = node.nextNode;

          if (FATHER?.YesNext) {
            // criar um elemento filho a partir do pai
            const CHILD = FATHER?.YesNext;

            // se o filho for um diamante então eu o passo para o somatório
            if (CHILD?.YesNext && CHILD?.YesNext?.formatType == target) {
              return 1 + this.testeDiretoFIlhosProximo(CHILD?.YesNext, target);
            }
          }

          return 1 + this.getNumberOfChildsOnNoLine(node.nextNode, target);
        } else if (node?.YesNext) {
        //  console.log("yes");
        } else {
          return this.getNumberOfChildsOnNoLine(node.nextNode, target);
        }
      }
    },

    async getNumberOfDiadmonsChilds(node, target) {
      if (node == null) return 0;
      else {
        let soma = 0;
        if (node?.formatType === target) soma += 1;
        if (node?.nextNode) {
      //    console.log(`node: ${node.label} -- entrando next --> ${node?.nextNode.label}`);
          soma += await this.getNumberOfDiadmonsChilds(node?.nextNode, target);
        }
        if (node?.YesNext) {
        //  console.log(`node: ${node.label} -- entrando yes --> ${node?.YesNext.label}`);
          soma += await this.getNumberOfDiadmonsChilds(node?.YesNext, target);
        }

        return soma;
      }
    },

    async getNumberOfChildsOnYesLine(node, target) {
      if (node == null) return 0;
      else {
        // BUSCO APENAS NA LINHA INICIAL RAIZ, POIS O COMPONENTE, MESMO ESTANDO NA LINHA YES, O PROXIMO ELEMENTO VEM NA LINHA NEXT

        if (node?.nextNode && node?.nextNode?.formatType == target) {
          let val = await this.searchOnlyOnYesLine(node.nextNode, target);
          //if(val && val > 0) return val || 0
          // console.log(
          //   `I: ${node?.label} ---- I.I: ${node?.nextNode?.label} ---- VALOR: ${val} --- METHOD: getNumberOfChildsOnYesLine`
          // );
          return val || 0;
        } else {
          return this.getNumberOfChildsOnYesLine(node.nextNode, target);
        }
      }
    },

    async searchOnlyOnYesLine(node, target) {
      if (node == null) return 0;
      else {
        // VERIFICAR SE NÓ, TEM COMPONENTES IRMÃOS DO TIPO DIAMANTE
        const {
          HAS_BRHOTER_INFORMATION_NEXT,
          HAS_BRHOTER_INFORMATION_YES,
        } = await this.hasSomeDiamondBrother(node, "nextNode", "diamond");

        const IS_CURRENT_YES_AND_HAVENT_BROTHER_ON_NEXT =
          node?.YesNext &&
          node?.YesNext.formatType == target &&
          !HAS_BRHOTER_INFORMATION_NEXT;

        const IS_CURRENT_YES_AND_HAVE_BROTHER_ON_NEXT =
          node?.YesNext &&
          node?.YesNext.formatType == target &&
          !HAS_BRHOTER_INFORMATION_NEXT;

        const IS_CURRENT_NEXT_AND_HAVENT_BROTHER_ON_YES =
          node?.nextNode &&
          node?.nextNode.formatType == target &&
          !HAS_BRHOTER_INFORMATION_YES;

        const IS_CURRENT_NEXT_AND_HAVE_BROTHER_ON_YES =
          node?.nextNode &&
          node?.nextNode.formatType == target &&
          HAS_BRHOTER_INFORMATION_YES;

        if (IS_CURRENT_YES_AND_HAVENT_BROTHER_ON_NEXT) {
          return 1 + (await this.searchOnlyOnYesLine(node.YesNext, target));
        } else if (IS_CURRENT_YES_AND_HAVE_BROTHER_ON_NEXT) {
          const PROMISES = [
            this.getElementsChildesSizes(node, "nextNode", "diamond"),
            this.getElementsChildesSizes(node, "YesNext", "diamond"),
          ];
          let NXT_NUM = 0;
          let YES_NUM = 0;

          await Promise.all(PROMISES).then((res) => {
            const [NXT, YES] = res;
            NXT_NUM = NXT;
            YES_NUM = YES;
          });

          if (NXT_NUM > YES_NUM)
            return NXT_NUM + (await this.searchOnlyOnYesLine(node.YesNext, target));
          else YES_NUM + (await this.searchOnlyOnYesLine(node.YesNext, target));
        } else if (IS_CURRENT_NEXT_AND_HAVENT_BROTHER_ON_YES) {
          return await this.searchOnlyOnYesLine(node?.nextNode, target);
        } else if (IS_CURRENT_NEXT_AND_HAVE_BROTHER_ON_YES) {
          const PROMISES = [
            this.getElementsChildesSizes(node, "nextNode", "diamond"),
            this.getElementsChildesSizes(node, "YesNext", "diamond"),
          ];
          let NXT_NUM = 0;
          let YES_NUM = 0;

          await Promise.all(PROMISES).then((res) => {
            const [NXT, YES] = res;
            NXT_NUM = NXT;
            YES_NUM = YES;
          });

          if (NXT_NUM > YES_NUM) {
            return NXT_NUM + (await this.searchOnlyOnYesLine(node?.nextNode, target));
          } else {
            return YES_NUM + (await this.searchOnlyOnYesLine(node?.nextNode, target));
          }
        } else if (node?.YesNext) {
          return await this.searchOnlyOnYesLine(node.YesNext, target);
        } else if (node?.nextNode) {
          return await this.searchOnlyOnYesLine(node?.nextNode, target);
        } else {
          return await this.searchOnlyOnYesLine(node.YesNext, target);
        }
      }
    },
    // verificar se tem componentes diamantes irmãos na mesma linha (nextNode)
    // hasSomeDiamondBrother(node, line, target) {
    //   console.log()
    //   if (node == null) return false;
    //   else {
    //     if (node[line] && node[line].formatType == target) {
    //       console.log('entrou no ok')
    //       return true;
    //     } else return this.hasSomeDiamondBrother(node[line], line, target);
    //   }
    // },

    async hasSomeDiamondBrother(node, line, target) {
      // console.log('entrou para o null', result)
      // if (result.HAS_BRHOTER_INFORMATION_NEXT || result.HAS_BRHOTER_INFORMATION_YES)
      //   return result;
      // else
      //   return {
      //     HAS_BRHOTER_INFORMATION_YES: false,
      //     HAS_BRHOTER_INFORMATION_NEXT: false,
      //   };

      const result = {
        HAS_BRHOTER_INFORMATION_YES: false,
        HAS_BRHOTER_INFORMATION_NEXT: false,
      };

      if (node == null) return result;
      else {
        if (node?.YesNext && node?.YesNext?.formatType == target) {
          result.HAS_BRHOTER_INFORMATION_YES = true;
          await this.hasSomeDiamondBrother(node?.nextNode, line, target);
        }

        if (node?.nextNode && node?.nextNode?.formatType == target) {
          result.HAS_BRHOTER_INFORMATION_NEXT = true;
          await this.hasSomeDiamondBrother(node?.nextNode, line, target);
        }
        return result;
      }
    },
    getElementsChildesSizes(node, line, target) {
      if (node == null) return 0;
      else {
        if (node[line] && node[line].formatType == target) {
          //  console.log(node?.label, line, node[line].label);
          return 1 + this.getElementsChildesSizes(node[line], line, target);
        } else return this.getElementsChildesSizes(node[line], line, target);
      }
    },

    test(node, target) {
      // RETORNAR UM POR DEFAULT, PORQUE SE EU FIZER MULTIPLICAÇÃO POR 0, O RESULTADO É 0
      if (node == null) return 0;
      else {
        if (node?.YesNext && node?.YesNext?.formatType == target) {
          return 1 + this.test(node.YesNext, target);
        } else {
          return this.test(node.YesNext, target);
        }
      }
    },

    // getNumberOfChildsOnYesLine(node, target) {
    //   // RETORNAR UM POR DEFAULT, PORQUE SE EU FIZER MULTIPLICAÇÃO POR 0, O RESULTADO É 0
    //   // REFINAR ESSA LÓGICA AQUI
    //   if (node == null) return 0;
    //   else {
    //     const IS_NEXT_NODE_CHILD_AN_DIAMOND =
    //       (node?.nextNode &&
    //         node?.nextNode.YesNext &&
    //         node?.nextNode.YesNext.formatType == target) ||
    //       (node?.YesNext && node?.YesNext.formatType == target);

    //     if (IS_NEXT_NODE_CHILD_AN_DIAMOND) {
    //       if (node?.nextNode?.YesNext)
    //         return 1 + this.getNumberOfChildsOnYesLine(node.nextNode.YesNext, target);
    //       else return this.getNumberOfChildsOnYesLine(node.nextNode, target);
    //     } else {
    //       return this.getNumberOfChildsOnYesLine(node.nextNode, target);
    //     }

    //     // else {
    //     //   return this.getNumberOfChildsOnYesLine(node.nextNode, target);
    //     // }

    //     // if (
    //     //   node?.nextNode &&
    //     //   node?.nextNode?.formatType == target &&
    //     //   node?.nextNode.YesNext &&
    //     //   node?.nextNode?.YesNext.formatType == target
    //     // ) {
    //     //   console.log('a', this.item.label)
    //     //   return 1 + this.getNumberOfChildsOnYesLine(node.nextNode, target);
    //     // } else
    //     // LÓGICA MAIS PRÓXIMA ABAIXO
    //     // if (
    //     //   (node?.nextNode &&
    //     //     node?.nextNode.YesNext &&
    //     //     node?.nextNode.YesNext.formatType == target) ||
    //     //   (node?.YesNext && node?.YesNext.formatType == target)
    //     // ) {
    //     //   console.log("b", this.item.label, node.nextNode.YesNext, node.YesNext);
    //     //   return (
    //     //     1 +
    //     //     this.getNumberOfChildsOnYesLine(node.nextNode.YesNext || node.YesNext, target)
    //     //   );
    //     // } else {
    //     //   console.log("c", this.item.label);
    //     //   return this.getNumberOfChildsOnYesLine(node.nextNode, target);
    //     // }
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.square {
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  &__remove {
    position: absolute;
    top: -40px;
    left: 34.5px;
    z-index: 3333;
  }
  &__content {
    // background: #0d99ff !important;
    padding: 0.5rem;
    width: 55px;
    height: 55px;
    border-radius: 10px;
    transform: rotate(-45deg);
    position: relative;
    margin-left: 10px;
    &--not-rotate {
      border: 10px solid transparent;
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: rotate(45deg);
      // transform-origin: center top;
      & > div {
        flex: 1 0 100%;
        //  border: 2px solid blue;
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 3 0 100%;
        padding-top: 47px;
      }
      &__text {
        flex: 0 100%;
        position: relative;
        top: 30px;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 15px;
        text-align: center;
        text-align: center;
        font-size: 0.75rem;
        letter-spacing: -0.011em;
        span {
          font-size: 0.75rem;
          letter-spacing: -0.011em;
        }
      }
    }
    &__line-top {
      border: 2px solid;
      // z-index: 99999;
      // transform: rotate(45deg);
      // background: red;
      position: absolute;
      top: 5px;
      right: -150px;
      transform: translate(50%, 0) rotate(45deg);
      transform-origin: center top;
    }
    // width: 0;
    // height: 0;
    // border: 90px solid yellow ;
    // border-bottom: 70px solid red;
    // position: relative;
    // top: -50px;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   left: -90px;
    //   top: 70px;
    //   width: 0;
    //   height: 0;
    //   border: 90px solid yellow ;
    //   border-top: 70px solid red;
    // }
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b3b3b3;
    background: #b3b3b3;
    height: 3px;
    // width: 80px;
    border-radius: 20px;

    span {
      background: #f4f4f4;
      color: #b3b3b3;
    }
  }
  .yes-line {
    cursor: pointer;
    position: absolute;
    bottom: -100px;
    left: 17px;
    height: 30px;
    border-radius: 0 0 0 20px;
    background: transparent;
    border: none;
    border-left: 3px solid #b3b3b3;
    border-bottom: 3px solid #b3b3b3;
    transition: 0.1s ease-in-out;

    span {
      background: transparent;
      position: absolute;
      bottom: -17px;
    }

    &__content {
      position: absolute;
      left: 158px;
      bottom: -30px;
      width: auto;
      height: auto;
    }

    &:hover {
      // background: red;
    }
  }

  &__line-plus {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    figure {
      width: 20px;
      height: 20px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .line {
      background: #b3b3b3;
      height: 3px;
      width: 20px;
      border-radius: 20px;
    }
  }
}
</style>
