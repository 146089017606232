<template>
  <div class="circle" @dblclick="joinInformations">
    <div
      v-if="
        (shouldShowRemoveButton && item.type != 'final') ||
        (shouldShowRemoveButtonOnProperElement && item.type != 'final')
      "
      class="circle__remove"
      @mouseover.self="handleMouseOverOnProperElement"
      @mouseleave.self="handleMouseLeaveOnProperElement"
    >
      <v-icon small @click="checkElementType(item, $event)"> mdi-pencil </v-icon>
    </div>
    <!-- @click.right.prevent="checkElementType(item, $event)" -->
    <!-- border: currentClickedNodeId && currentClickedNodeId === item.id &&  !shouldValidate? '3px dashed #E46651 !important' : '' -->
    <div
      class="circle__content"
      :style="{
        'background-color': getColor,
        border: shouldValidate && !currentClickedNodeId ? '1px solid red !important' : '',
        border:
          clickedNodesList.length &&
          clickedNodesList.find((el) => el.id == item.id) &&
          !shouldValidate
            ? '3px dashed #E46651 !important'
            : '',
      }"
      @click="$emit('node_click', item.id)"
      @mouseover.self="handleMouseOver"
      @mouseenter.self="handleMouseEnter"
      @mouseleave.self="handleMouseLeave"
    >
      <z-context-menu
        ref="contextMenu"
        :options="optionsMenu"
        @click="onClickContextAction"
      />
      <div v-if="icon" class="circle__content__icon">
        <v-icon color="white"> {{ icon }}</v-icon>
      </div>
      <div class="circle__content__text">
        <span v-if="render">{{ item.label }} </span>
      </div>
    </div>
    <div
      v-if="hasNextNode"
      @mouseover="showAddButton()"
      @mouseleave="hideAddButton()"
      :class="{ circle__line: !currentNodeHaveMerge }"
      :style="[
        currentNodeHaveMerge ? leftLineSize : currentNodeMakesMerge ? leftLineSize2 : '',
      ]"
      style="margin-right: 10px"
    >
      <figure
        v-if="!isReportRender && !currentNodeHaveMerge"
        @click="linkClick"
        :id="item.id"
        style="
          display: none;
          position: relative;
          top: -11px;
          left: 3px;
          z-index: 5 !important;
        "
      >
        <img
          style="margin-left: 15px; position: relative"
          src="/icons/add-circle-rounded-icon.svg"
        />
      </figure>
    </div>
  </div>
</template>

<script>
import shapesMixin from "./shapesMixin";
export default {
  name: "FlowCircle",
  mixins: [shapesMixin],
  inject: ["databaseConfigId", "currentClickedNodeId", "clickedNodesList"],
  props: {
    item: { type: Object, default: null, required: true },
    icon: { type: String, default: "" },
    color: { type: String, default: "#9747ff" },
    isReportRender: { type: Boolean, required: false, default: false },
    hasNextNode: { type: Boolean, default: false },
    shouldValidate: { type: Boolean, default: false },
  },
  data() {
    return {
      render: true,
      optionsMenu: [
        { action: "delete", text: "Remover", value: 0 },
        { action: "rename", text: "Renomear", value: 1 },
      ],
    };
  },
  computed: {
    getColor() {
      let IS_AUDIENCE =
        this.item.type.includes("selectAudience") &&
        this.item.isDataSelected &&
        this.databaseConfigId;

      let IS_TIMER =
        this.item.type.includes("await") &&
        this.item.isDataSelected &&
        this.item.wait &&
        this.item.waitValue;

      let SELECT_COLOR =
        this.item.type == "final"
          ? this.isReportRender
            ? "#DCDCDC"
            : "#14AE5C"
          : IS_AUDIENCE || IS_TIMER
          ? this.color
          : "#DCDCDC";

      // caso o chip seja do facebook, deve sobrecrever lógica com a cor do chip
      this.item.type.includes("sendGoogle") &&
      this.item.isDataSelected &&
      this.item.collectionId
        ? (SELECT_COLOR = this.item.color)
        : "";

      this.item.type.includes("sendWhatsApp") &&
      this.item.templateId &&
      this.item.isDataSelected
        ? (SELECT_COLOR = this.item.color)
        : "";

      this.item.type.includes("tag") &&
      this.item.tags &&
      this.item.isDataSelected
        ? (SELECT_COLOR = this.item.color)
        : "";

      return `${SELECT_COLOR} !important`;
    },
    hasChildsElements() {
      const IS_TO_RETURN =
        (this.item.type != "selectAudience" && this.item.nextNode) || this.item.YesNext
          ? true
          : (this.item.type.includes('"selectAudience"') &&
              this.item?.nextNode?.type == "final") ||
            this.item?.YesNext?.type == "final"
          ? true
          : false;

      return IS_TO_RETURN;
    },
    shouldShowRemoveButton() {
      return this.isActive && this.hasChildsElements;
    },
    shouldShowRemoveButtonOnProperElement() {
      return this.isHoverOnProperElement && this.hasChildsElements;
    },
    leftLineSize() {
      const style = {
        position: "absolute",
        bottom: "30px",
        left: "70px",
        borderBottom: "3px solid #b3b3b3",
        borderRight: "3px solid #b3b3b3",
        background: "transparent",
        height: "30px",
        width: "60px",
        borderRadius: "0 0 20px 0 ",
        cursor: "default",
      };

      // informações montadas no mixin
      if (this.nodeIndexToJoin > this.selfIndexToJoin) {
        // let controller = this.selfIndexToJoin;
        let t = this.nodeIndexToJoin - this.selfIndexToJoin;
        let newWidth = 100 * t;

        // adicionar mais uma pequena porcentagem a partir do tamanho do newWidth atual ?
        if (t > 1 && t < 3) {
          let percent = (newWidth * 20.5) / 100;
          newWidth += percent;
        }

        if (t >= 3) {
          let percent = (newWidth * 25) / 100;
          newWidth += percent;
        }
        style.width = `${newWidth}px`;
      }

      return style;
    },
    leftLineSize2() {
      const style = {
        border: "1px solid #b3b3b3;",
        background: "#b3b3b3;",
        height: " 3px;",
        width: "60px;",
        borderRadius: "20px;",
        cursor: "pointer;",
      };

      // informações montadas no mixin
      if (this.selfIndexMakesMerge < this.nodeIndexMakeMergeTojoin) {
        // let controller = this.selfIndexToJoin;
        //   let t = this.nodeIndexMakeMergeTojoin - this.selfIndexMakesMerge;
        let newWidth = 100 * this.nodeIndexMakeMergeTojoin;
        // console.log(t, 't here')

        // adicionar mais uma pequena porcentagem a partir do tamanho do newWidth atual ?
        // if (t > 1 && t < 3) {
        //   let percent = (newWidth * 20.5) / 100;
        //   newWidth += percent;
        // }

        if (this.nodeIndexMakeMergeTojoin > 3) {
          let percent = (newWidth * 10) / 100;
          newWidth += percent;
        }

        if (this.nodeIndexMakeMergeTojoin >= 5) {
          let percent = (newWidth * 5) / 100;
          newWidth += percent;
        }
        style.width = `${newWidth}px`;
      }

      return style;
    },
  },
  watch: {
    // item(value) {
    //   this.render = false;
    //   this.render = true;
    //   console.log(value)
    //   this.$forceUpdate()
    // }
    item: {
      handler(nv) {
        console.log(nv);
      },
      deep: true,
    },
  },
  methods: {
    joinInformations() {
      if (this.item.label.toLowerCase() == "final") {
        this.$emit("joinInformations", this.item);
      }
    },
    checkElementType(t, evt) {
      if (t.type != "final") {
        if (t.type.includes("selectAudience")) {
          if (
            (t.nextNode && t.nextNode.type === "final") ||
            (t.YesNex && t.YesNex.type === "final")
          )
            this.$refs.contextMenu.open(evt, "data");
        } else this.$refs.contextMenu.open(evt, "data");
      }
    },
    onClickContextAction({ option }) {
      switch (option.value) {
        case 0:
          this.deleteContent();
          break;
        case 1:
          this.renameContent();
          break;
      }
    },
    renameContent() {
      if (this.item.label.toLowerCase() != "final") this.$emit("rename_node", this.item);
    },
    deleteContent() {
      if (this.item.label.toLowerCase() != "final")
        if (this.hasChildsElements) this.$emit("node_click_right", this.item.id);
    },
    linkClick() {
      this.$emit("linkClick");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.circle {
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  &__remove {
    position: absolute;
    top: -28.5px;
    left: 23.5px;
    // border-radius: 50%;
    // width: 25px;
    // height: 25px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // cursor: pointer;
    // background: #e7f0f8;
  }
  &__content {
    cursor: pointer;
    background: #9747ff !important;
    padding: 0.5rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    & > div {
      flex: 1 0 100%;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__text {
      position: relative;
      top: 8px;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 15px;
      span {
        font-size: 0.75rem;
        letter-spacing: -0.011em;
      }
    }
  }
  &__line {
    border: 1px solid #b3b3b3;
    background: #b3b3b3;
    height: 3px;
    width: 60px;
    border-radius: 20px;
    cursor: pointer;
  }
}
</style>
