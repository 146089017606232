var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle",on:{"dblclick":_vm.joinInformations}},[(
      (_vm.shouldShowRemoveButton && _vm.item.type != 'final') ||
      (_vm.shouldShowRemoveButtonOnProperElement && _vm.item.type != 'final')
    )?_c('div',{staticClass:"circle__remove",on:{"mouseover":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleMouseOverOnProperElement.apply(null, arguments)},"mouseleave":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleMouseLeaveOnProperElement.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.checkElementType(_vm.item, $event)}}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('div',{staticClass:"circle__content",style:({
      'background-color': _vm.getColor,
      border: _vm.shouldValidate && !_vm.currentClickedNodeId ? '1px solid red !important' : '',
      border:
        _vm.clickedNodesList.length &&
        _vm.clickedNodesList.find((el) => el.id == _vm.item.id) &&
        !_vm.shouldValidate
          ? '3px dashed #E46651 !important'
          : '',
    }),on:{"click":function($event){return _vm.$emit('node_click', _vm.item.id)},"mouseover":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleMouseOver.apply(null, arguments)},"mouseenter":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleMouseEnter.apply(null, arguments)},"mouseleave":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleMouseLeave.apply(null, arguments)}}},[_c('z-context-menu',{ref:"contextMenu",attrs:{"options":_vm.optionsMenu},on:{"click":_vm.onClickContextAction}}),(_vm.icon)?_c('div',{staticClass:"circle__content__icon"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.icon))])],1):_vm._e(),_c('div',{staticClass:"circle__content__text"},[(_vm.render)?_c('span',[_vm._v(_vm._s(_vm.item.label)+" ")]):_vm._e()])],1),(_vm.hasNextNode)?_c('div',{class:{ circle__line: !_vm.currentNodeHaveMerge },staticStyle:{"margin-right":"10px"},style:([
      _vm.currentNodeHaveMerge ? _vm.leftLineSize : _vm.currentNodeMakesMerge ? _vm.leftLineSize2 : '',
    ]),on:{"mouseover":function($event){return _vm.showAddButton()},"mouseleave":function($event){return _vm.hideAddButton()}}},[(!_vm.isReportRender && !_vm.currentNodeHaveMerge)?_c('figure',{staticStyle:{"display":"none","position":"relative","top":"-11px","left":"3px","z-index":"5 !important"},attrs:{"id":_vm.item.id},on:{"click":_vm.linkClick}},[_c('img',{staticStyle:{"margin-left":"15px","position":"relative"},attrs:{"src":"/icons/add-circle-rounded-icon.svg"}})]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }